<template>
    <app-header />
    <tool-list />
    <app-footer/>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from '../components/AppFooter.vue';
import ToolList from '../components/ToolList.vue';
export default {
    name: "Tools",
    data() {
    },
    components: {
        AppHeader,
        AppFooter,
        ToolList,
    },
    props: {},
    methods: {},
};
</script>

<style></style>
