<template>
  <app-header />
  <div class="container mx-auto px-4 pb-24">
    <div class="max-w-4xl pt-24 text-xl leading-relaxed">
      <p class="mb-8 text-3xl">
        Neoco is based on research by the team from <a href="https://loopers.co" class="font-semibold underline">Loopers</a>. We're foraging 
        for new thinking and practices to help us innovate, stay inspired and keep out of
        the grind. We share our research and insights publicly here, and encourage anyone to copy, edit and contribute to
        them.
      </p>
      <p class="mb-8 text-3xl">
        Reach out here: <a href="mailto:hi@loopers.co" class="font-semibold underline">Hi[at]Loopers.co</a>
      </p>
      <p class="mb-4">
        Neoco can be thought of as a blueprint for the neo-company, pointing the way towards how we might improve our
        organisations and industrial lives. Neoco is not a fixed concept, it's an evolving project that is never done.
        "Neo" here doesn’t just mean new, it's more broadly about any new or smart ideas, tools and processes in the world
        of work, business, innovation and creativity. Naturally, the content here is subjective and incomplete. We
        generally avoid making recommendations and don't try to cover every option, we just stay on the hunt for
        inspiration and share what we find.
      </p>
      <p class="mb-4">
        Neoco is open for anyone to participate. If you see yourself as a neophile (a lover of the new) and are keen to
        explore better ways of working, then consider yourself a fellow neonaut. Welcome aboard.
      </p>
      <p class="mb-8">
        Neoco was launched with funding from <a href="https://www.futurescreens.org/" class="font-semibold underline">Future Screens NI</a> and
        <a href="https://www.northernirelandscreen.co.uk/" class="font-semibold underline"> Northern Ireland Screen </a> as part of the
        <i>Rewriting the Narrative</i> project, 2020. You can read the original brief for the project
        <a href="https://bit.ly/UC_Ovw" class="font-semibold underline">here</a>.
      </p>
      <h2 class="text-2xl font-bold mb-4">The Loopers Team</h2>
      <p class="mb-8">
        We are a multidisciplinary team, with pragmatic solutions for enhancing outcomes across product, data,
        engineering, AI, design, ethics, creativity and operations. We have built our practice on a simple process of
        getting our clients into rapid loops that get to customer and data-driven outcomes faster. Loop yourself in with
        us at <a href="https://loopers.co" class="font-semibold underline">loopers.co</a>
      </p>
      <h2 class="text-2xl font-bold mb-4">Disclaimers and Biases</h2>
      <p class="mb-4">
        Our work is inevitably biased towards the cultural milieu of its principle author, me: Ben Bland. Therefore I
        expect that Neoco's current incarnation is biased by some of the following aspects:
      </p>
      <ul class="mb-4 fix-list">
        <li>English speaking, UK/British, probably some US and maybe some Europe.</li>
        <li>Tech (especially digital), creative industries, and business (especially startups).</li>
        <li>
          I have had a privileged life so far, with access to a decent education, easy employment, plenty of love and
          support, and minimal discrimination.
        </li>
        <li>
          I have also had an easy time of the pandemic so far, switching to working from home meant little to me and I can
          live comfortably in my house, doing tech and creative work online and having no dependents to support.
        </li>
      </ul>
      <p class="mb-4">
        Whether due to expedience, laziness or some other reason, the work behind Neoco has been light on the following
        topics, which perhaps deserve more attention:
      </p>
      <ul class="mb-4 fix-list">
        <li>Activism, social justice and equality.</li>
        <li>Mental and physical health issues (e.g. isolation).</li>
        <li>Socioeconomic &amp; financial challenges (e.g. due to pandemic lockdown).</li>
      </ul>
      <p class="mb-4">
        I chose not to dwell much on the Covid-19 pandemic, even though that was the trigger for the grant that funded the
        research behind Neoco.
      </p>
      <p class="mb-4">
        While this project is built on a funded research project, it is not strictly academic. There are claims and
        concepts published here that are not directly referenced to sources, and based on widely varied levels of
        evidential data. Please be assured though, that the aim has always been to be open and balanced, and only to
        present claims when they are based on some reasonable external evidence, whether that's from editorial content,
        published research data, direct personal experience or other sources.
      </p>
      <p class="mb-8">
        If the balance of objective commentary here is tipped in any direction, the most obvious tendency is towards a
        somewhat starry-eyed prospect of social and corporate revolution, through which our species will develop
        increasingly popular ways of interacting with each other that favour mental and physical wellbeing, social
        equality, ecosystem stewardship and other ethical objectives. You might do well to raise a skeptical eyebrow at
        any content you feel might be a little too optimistic or disruptive... but we can dream, right?
      </p>
      <h2 class="mb-4 text-2xl font-bold">Data Protection</h2>
      <p class="">
        From the outset, we have endeavoured to exceed the requirements of the General Data Protection Regulation. All
        potentially sensitive data (e.g. interviewees' personal information) is stored exclusively in a cloud-based
        database with multi-factor authentication, only accessible to approved team members who have signed and completed
        the project’s privacy onboarding process. A sensitive data registry is retained, along with any privacy agreements
        and similar materials, for future audit.
      </p>
    </div>
  </div>
  <app-footer/>
</template>

<script>
import AppFooter from '../components/AppFooter.vue';
import AppHeader from "../components/AppHeader.vue";
export default {
  name: "About",
  components: {
    AppHeader,
    AppFooter,
  },
  props: {},
};
</script>

<style>
  .fix-list {
    list-style-position: outside;
    list-style-type: disc;
    padding-left: 2rem;
  }
  .fix-list li {
    margin-bottom: 0.5rem;
  }
</style>
