<template>
  <div class="footer">
    <div class="container mx-auto py-28 px-4">
      <a href="https://www.producthunt.com/posts/neoco?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-neoco" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=295544&theme=light" alt="Neoco - Curated set of 300+ tools & patterns for brighter business | Product Hunt" style="width: 250px; height: 54px; margin: 0 auto; " width="250" height="54" /></a>
      <a href="https://loopers.co"><img src="../assets/built-loopers.svg" class="built-logo mx-auto w-64 mt-8"></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppFooter',
  props: {
    
  },
}
</script>
<style lang="scss" scoped>
  .footer {
    background-color: black;
    
  }
</style>