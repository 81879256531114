<template>
  <div class="container mx-auto px-4 mb-4 flex flex-col">
    <div class="mb-4">
      <h2 class="tools-intro__subtitle pt-14">Access the <a href="https://airtable.com/shr3lJ3riyGkinCMx">source data</a> or <a href="https://airtable.com/shrb0OVAXhG8snmoP">suggest additions</a>.</h2>
    </div>
    <div class="flex flex-row items-center relative pb-4">
      <select
        class="filter-dropdown mr-2 text-xl border-black border-4 p-2 appearance-none w-80 bg-dropdown bg-no-repeat flex-grow lg:flex-grow-0"
        v-model="selected"
        @change="filter"
        name="tags"
      >
        <option value="" disabled selected>Filter by category...</option>
        <option v-for="item in dropdown" v-bind:key="item" :value="item">{{ item }}</option>
      </select>
      <div
        class="rounded-full h-10 w-10 flex items-center justify-center bg-black text-white"
        v-if="selected == ''"
      ></div>
      <div
        class="rounded-full h-10 w-10 flex items-center justify-center bg-black text-white cursor-pointer"
        v-if="selected !== ''"
        @click="clear"
      >
        X
      </div>
    </div>
  </div>

  <div class="container mx-auto px-2 pb-16">
    <div class="flex flex-row flex-wrap">
      <div class="flex flex-row w-full md:w-1/2 xl:w-1/3 2xl:w-1/4 px-2 mb-4" v-for="item in filtered" v-bind:key="item['ID']">
        <div class="relative w-full border-4 border-black">
          <a v-bind:href="item['URL']" class="absolute w-full h-full"></a>
          <img src="../assets/small-arrow.svg" class="absolute top-2 right-2 w-6" />
          <h1 class="text-xl border-b-4 border-black font-bold p-6">
            {{ item["Name"] }}
          </h1>
          <p class="text-lg p-6">
            {{ item["Description"] }}
          </p>
          <div class="px-6 mb-6">
            <span class="text-white mr-2 inline-block bg-black rounded-full py-2 px-4 mb-2" v-for="tag in item['Tags']" v-bind:key="tag">
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tools from "../tools.js";
export default {
  name: "ToolList",
  props: {},
  components: {
    
  },
  data() {
    return {
      records: [],
      filtered: [],
      selected: "",
      dropdown: [],
    };
  },
  mounted() {
    var dropdown = new Set();
    tools.forEach((record) => {
      this.records.push(record);
      this.filtered.push(record);
      record["Tags"].forEach((x) => {
        dropdown.add(x);
      });
    });
    var ret = Array.from(dropdown);
    this.dropdown = ret;
  },
  methods: {
    clear() {
      this.selected = "";
      this.filtered = this.records;
    },
    filter() {
      var ret = [];
      this.records.forEach((item) => {
        item["Tags"].forEach((x) => {
          if (this.selected == x) {
            ret.push(item);
          }
        });
      });
      this.filtered = ret;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .filter-dropdown {
    background-size: 2rem;
    background-position-x: calc(100% - 10px);
    background-position-y: 45%;
  }
  .tool-illustration {
    max-width: 300px;
    padding: 3rem 0 1rem 0;
  }
  .tools-intro__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 3rem;
    @media (min-width: 1024px){
      font-size: 48px;
    }
  }
  .tools-intro__subtitle {
    font-size: 22px;
    font-weight: 600;
    a {
      text-decoration: underline;
    }
    @media (min-width: 1024px){
      font-size: 36px;
    }
  }
</style>
