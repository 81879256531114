<template>
  <app-header/>
  <div class="bg-black text-white">
    <div class="container mx-auto px-4 pb-12">
      <div class="book-intro">
        <div class="book-intro__title">
          <h2>Playbook</h2>
          <h1>Pioneering Pathways to Brighter Business</h1>
          <p>Learn the plays of organisations that zig when others zag. Pick up quick takeaways on <a href="https://playbook.neoco.io/digital-evolution">digital transformation</a>, <a href="https://playbook.neoco.io/cooperative-behaviours">collaboration</a>, <a href="https://playbook.neoco.io/rethinking-organisation">alternative org structures</a>, <a href="https://playbook.neoco.io/principled-ethos">ethics</a> and more...</p>
        </div>
        <div class="book-intro__image"><img src="../assets/neoco-book-cover.png"></div>
        <div class="book-intro__copy">
          <ul>
            <li>40+ innovation strategies, as well as:</li>
            <li>50+ <a href="https://playbook.neoco.io/Library-54255347c71449ac827df1ec6b250f93">guidance resources</a>.</li>
            <li>16+ <a href="https://playbook.neoco.io/Pioneers-9fd0653edce0450ca94a8f52ef0aeb47">case studies</a>.</li>
          </ul>
          <div class="outline-button">
            <a href="https://playbook.neoco.io" class="outline-button__link">Read the Playbook</a>
            <div class="powered-by">
              <img src="../assets/notion-logo.png">
              <p>powered by <a href="https://notion.so" class="outline-button__inline-link">notion</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mx-auto px-4 py-20">
    <div class="tools-intro">
      <div class="tools-intro__image"><img src="../assets/tools.svg" alt="Neoco Toolkit"></div>
      <div class="tools-intro__intro">
        <h1 class="tools-intro__title">Neoco Toolkit</h1>
        <h2 class="tools-intro__subtitle mb-4">Equip Yourself for the Next Industrial Revolution</h2>
        <p class="tools-intro__copy pb-4">Browse <span>240+</span> tools for radical work, based on recommendations from leading creatives and innovators. Tools for <span>design</span>, <span>collaboration</span>, <span>lo-to-no-code tooling</span>, <span>business operations</span>, <span>remote work</span> and more...</p>
        <p class="tools-intro__copy mb-8">You can also access the <a href="https://airtable.com/shr3lJ3riyGkinCMx">source data</a> and <a href="https://airtable.com/shrb0OVAXhG8snmoP">suggest additions</a>.</p>
        <div class="outline-button dark">
          <router-link to="/tools" class="outline-button__link">View the Tools</router-link>
          <div class="powered-by">
            <img src="../assets/airtable.svg">
            <p>powered by <a href="https://airtable.com" class="outline-button__inline-link">airtable</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-footer py-20">
    <div class="sub-footer__container mx-auto px-4">
      <h1 class="tools-intro__title">What is All This?</h1>
      <h2 class="tools-intro__subtitle mb-4">Welcome Fellow Neonaut!</h2>
      <p class="tools-intro__copy pb-4">Neoco was built by the team at <a href="https://loopers.co">Loopers</a>, based on nine months of researching answers to the question, "what would you say to people who are seeking better ways of working and don’t want to go back to the “old normal”?</p>
      <p class="tools-intro__copy mb-8">You have discovered a very early version of Neoco. Your suggestions, ideas and critical feedback are keenly welcomed.</p>
      <div class="outline-button dark mb-20">
        <a href="mailto:hi@loopers.co" class="outline-button__link">Let's Talk</a>
      </div>
      <h1 class="tools-intro__title mb-4">Join the Neoco Community</h1>
      <p class="tools-intro__copy mb-8">Register for an occasional email on future insights, guides, tools and services from our team. No spam, no fluff, just useful stuff.</p>
      <div class="outline-button dark">
        <a href="https://share.hsforms.com/1a7hDVqy1TQuh51LsKraCigbs8iv" class="outline-button__link">Subscribe</a>
      </div>
    </div>
  </div>
  <app-footer/>
</template>

<script>
import AppFooter from '../components/AppFooter.vue';
import AppHeader from '../components/AppHeader.vue';
export default {
  name: "Home",
  components: {
    AppHeader,
    AppFooter
    
  },
  props: {
    
  },
  
};
</script>

<style lang="scss" scoped>
  .sub-footer {
    background-color: #DCDCDC;
  }
  .sub-footer__container {
    text-align: center;
    max-width: 900px;
  }
  .tools-intro {
    @media (min-width: 1024px){
      display: flex;
    }
  }
  .tools-intro__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 3rem;
    @media (min-width: 1024px){
      font-size: 48px;
    }
  }
  .tools-intro__subtitle {
    font-size: 22px;
    font-weight: 600;
    @media (min-width: 1024px){
      font-size: 36px;
    }
  }
  .tools-intro__image {
    img {
      width: 100%;
    }
    @media (max-width: 1023px){
      max-width: 300px;
      margin-bottom: 2.5rem;
    }
    @media (min-width: 1024px){
      flex-basis: 40%;
      padding-right: 120px;
    }
  }
  .tools-intro__intro {
    flex-basis: 60%;
  }
  .tools-intro__copy {
    font-size: 18px;
    span {
      font-weight: 600;
    }
    a {
      font-weight: 600;
      text-decoration: underline;
    }
    @media (min-width: 1024px){
      font-size: 24px;
    }
  }
  .book-intro {
    @media (min-width: 1024px){
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 1fr;
    }
    @media (min-width: 1280px){
      grid-template-columns: 1fr 1fr;
    }
  }
  .book-intro__title {
    margin-bottom: 2rem;
    h1 {
      font-size: 2.5rem;
      font-weight: 800;
      line-height: 3rem;
      margin-bottom: 2rem;
      @media (max-width: 1023px) {
        max-width: 500px;        
      }
      @media (min-width: 768px) {
        font-size: 3.2rem;
        line-height: 3.9rem;
      }
      @media (min-width: 1024px){
        font-size: 3.5rem;
        line-height: 4.2rem;
      }
      @media (min-width: 1280px){
        font-size: 4rem;
      }
    }
    p, h2 {
      font-weight: 400;
      font-size: 18px;
      @media (max-width: 1023px) {
        max-width: 500px;        
      }
      @media (min-width: 1024px){
        font-size: 22px;
      }
      @media (min-width: 1280px){
        font-size: 24px;
      }
    }
    h2 {
      font-weight: 600;
      padding-top: 2rem;
    }
    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }
  .book-intro__image {
    img {
      width: 300px;
      @media (min-width: 1024px) and (max-width: 1279px){
        width: auto;
        max-width: 380px;
      }
      @media (min-width: 1280px){
        width: auto;
      }
    }
    margin-bottom: 1rem;
    @media (min-width: 1024px){
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
      padding-left: 1rem;
      justify-self: center;
    }
  }
  .book-intro__copy {
    ul {
      margin-bottom: 2rem;
    }
    li {
      font-size: 22px;
      font-weight: 600;
      line-height: 2rem;
      a {
        text-decoration: underline;
      }
      @media (min-width: 1024px){
        font-size: 28px;
        line-height: 3rem;
      }
      @media (min-width: 1280px){
        font-size: 36px;
      }
    }
  }
  .page-holder {
    height: 2000px; 
  }
  .outline-button {
    display: inline-block;
    .outline-button__link {
      font-size: 18px;
      font-weight: 900;
      border: 4px solid white;
      padding: 1rem 2rem;
      display: inline-block;
      margin-bottom: 15px;
      @media (min-width: 1024px){
        font-size: 24px;
      }
    }
    .outline-button__inline-link {
      text-decoration: underline;
    }
    .powered-by {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 22px;
        margin-right: 10px;
        @media (min-width: 1024px){
          width: 30px;
        }
      }
      p {
        font-size: 14px;
        @media (min-width: 1024px){
          font-size: 16px;
        }
      }
    }
    &.dark {
      .outline-button__link {
        border: 6px solid black;
      }
    }
  }
</style>
