<template>
  <div class="w-full fixed top-0 left-0 w-full bg-black z-10">
    <div class="relative container mx-auto px-4 py-6">
      <img src="../assets/arrow-white.svg" class="w-6 absolute top-6 left-6">
      <ul class="container mx-auto text-white pl-14">
        <li class="inline-block pr-4 lg:pr-8  lg:text-xl font-bold"><router-link to="/">Home</router-link></li>
        <li class="inline-block pr-4 lg:pr-8 lg:text-xl font-bold"><a href="https://playbook.neoco.io">Playbook</a></li>
        <li class="inline-block pr-4 lg:pr-8 lg:text-xl font-bold"><router-link to="/tools">Tools</router-link></li>
        <li class="inline-block lg:text-xl font-bold"><router-link to="/about">About</router-link></li>
      </ul>
    </div>
  </div>
  <div class="bg-black pt-28 pb-8 lg:pt-44 lg:pb-14">
    <div class="container mx-auto px-4">
      <img src="../assets/neoco-logo-white.svg" alt="Neoco" class="main-logo">
      <h2 class="text-white font-semibold pt-4">Resources for Radical Operations</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppHeader',
  props: {
    
  },
}
</script>
<style lang="scss" scoped>
  .main-logo {
    width: 250px;
    @media (min-width: 1024px){
      width: 350px;
    }
  }
</style>